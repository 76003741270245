import * as React from 'react'
import styles from './styles.module.scss'
export interface LoadingProps {
}

export default function Loading(props: LoadingProps) {
  return (
    <div className={styles.dd_loading}><div></div><div></div></div>
  )
}
