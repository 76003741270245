import React, { useState, useEffect } from 'react'
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Image from 'next/image'
import Link from 'next/link'
import useAuth from '@/lib/useAuth'
import Loading from '@/components/shared/Loading'
export interface MainLayoutProps {
  children: any
}

export default function MainLayout(props: MainLayoutProps) {
  const isAuthenticated = useAuth(true)

  if (!isAuthenticated) return <div 
  style={{
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'fixed',
    width: '100%',
    zIndex: 9999,
    backgroundColor: '#fff',
  }}>
    <Loading />
  </div>

  return <div>{props.children}</div>
}
