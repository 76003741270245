import React from 'react'
import styles from './Welcome.module.scss'
import Stack from '@mui/material/Stack'
import Image from 'next/image'
import Button from '@mui/material/Button'
import { useRouter } from 'next/router'
import { getStoreLink } from '@/lib/utils'

export interface WelcomeProps {}

export default function Welcome(props: WelcomeProps) {
  const router = useRouter()
  const handleRedirectStoreInformation = () => {
    router.push(getStoreLink('/onboarding'))
  }
  const welcomeBackground = `${process.env.PUBLIC_URL}/onboarding-welcome-background.svg`

  return (
    <div
      className={styles.welcome}
      style={{ background: `url(${welcomeBackground})`, backgroundSize: 'cover' }}
    >
      <Stack direction="row" justifyContent="space-between" className={styles.welcome__content}>
        <Stack direction="column">
          <div className={styles.welcome__logo}>
            <Image
              src={`${process.env.PUBLIC_URL}/logo-square.svg`}
              alt="Logo"
              width={56}
              height={56}
              className={`dd-img-fluid`}
            />
          </div>
          <Stack direction="column" alignItems="center" className={styles.welcome__heading}>
            <h1 className={styles.welcome__title}>
              Welcome to <br /> DingDoong Digital Menu
            </h1>
            <Button
              variant="contained"
              className={styles.welcome__button}
              onClick={handleRedirectStoreInformation}
            >
              Get started !
            </Button>
          </Stack>
        </Stack>
        <div className={`${styles.wrapper} ${styles['wrapper--vertical']}`}>
          <div className={`${styles.marquee} ${styles['marquee--vertical']}`}>
            <div className={`${styles.marquee__group}`}>
              <Image
                src={`${process.env.PUBLIC_URL}/onboarding-welcome-phone1.png`}
                alt="Iphone1"
                width={287}
                height={386}
                className={`dd-img-fluid ${styles.welcome__image}`}
              />
              <Image
                src={`${process.env.PUBLIC_URL}/onboarding-welcome-phone1.png`}
                alt="Iphone1"
                width={287}
                height={386}
                className={`dd-img-fluid ${styles.welcome__image}`}
              />
            </div>
          </div>
          <div
            className={`${styles.marquee} ${styles['marquee--reverse']} ${styles['marquee--vertical']}`}
          >
            <div className={`${styles.marquee__group}`}>
              <Image
                src={`${process.env.PUBLIC_URL}/onboarding-welcome-phone1.png`}
                alt="Iphone1"
                width={287}
                height={386}
                className={`dd-img-fluid ${styles.welcome__image}`}
              />
              <Image
                src={`${process.env.PUBLIC_URL}/onboarding-welcome-phone1.png`}
                alt="Iphone1"
                width={287}
                height={386}
                className={`dd-img-fluid ${styles.welcome__image}`}
              />
            </div>
          </div>
        </div>
      </Stack>
      <Image
        src={`${process.env.PUBLIC_URL}/onboarding-welcome-illustration.svg`}
        alt="Illustration"
        width={316}
        height={301}
        className={`dd-img-fluid ${styles['welcome__image-ellipse']}`}
      />
    </div>
  )
}
