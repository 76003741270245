import React, { useEffect, useState } from 'react'
import Layout from '@/components/app/Layout'
import LayoutMain from '@/components/app/LayoutMain'
import Link from 'next/link'
import { resetStateSignOut } from '@/features/user/user-slice'
import { useAppDispatch, useAppSelector } from '@/app/hook'
import { useRouter } from 'next/router'
import LoadingButton from '@mui/lab/LoadingButton'
import Welcome from '@/components/onboarding/Welcome'
import { storeList } from '@/features/store/store-slice'
import { signOut } from 'next-auth/react'
import Loading from '@/components/shared/Loading'
import { useSession } from 'next-auth/react'
import { tableList } from '@/features/table/table-slice'
import { getStoreLink, openSiteManager } from '@/lib/utils'

export interface HomePageProps {}

export default function HomePage(props: HomePageProps) {
  const dispatch = useAppDispatch()
  const isSignOut = useAppSelector((state: any) => state.user.isSignOut)
  const isLoading = useAppSelector((state: any) => state.user.isLoading)
  const tokens = useAppSelector((state: any) => state.user.tokens)
  const stores = useAppSelector((state: any) => state.store.stores)
  const tables = useAppSelector((state: any) => state.table.tables)
  const isLoadingStoreList = useAppSelector((state: any) => state.store.isLoadingList)
  const store = useAppSelector((state: any) => {
    if (state.store.stores.length) {
      return state.store.stores[0]
    }
  })
  const router = useRouter()
  const [isLogged, setIsLogged] = useState(true)
  const { data: session }: any = useSession()

  const handleSignOut = () => {
    signOut({ redirect: false })
    router.replace('/login')
    // dispatch(signOut({}))
  }

  const handleOpenSite = () => {
    openSiteManager(store.subdomain)
  }

  useEffect(() => {
    if (session) {
      dispatch(storeList({}))
        .unwrap()
        .then((originalPromiseResult: any) => {
          if (originalPromiseResult.data.length) {
            dispatch(tableList({ store_id: originalPromiseResult.data[0].ID }))
          }
        })
        .catch((rejectedValueOrSerializedError: any) => {})
    }
  }, [dispatch, session])

  if (isLoadingStoreList) {
    return (
      <div
        style={{
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'fixed',
          width: '100%',
          zIndex: 9999,
          backgroundColor: '#fff',
        }}
      >
        <Loading></Loading>
      </div>
    )
  }

  return !tables.length || !stores.length ? (
    <Layout>
      <Welcome></Welcome>
    </Layout>
  ) : (
    <LayoutMain>
      <ul>
        <li>
          <Link href="/">Home</Link>
        </li>
        <li>
          <Link href="/login">Login</Link>
        </li>
        <li>
          <Link href="/sign-up">Register</Link>
        </li>
        <li>
          <Link href="/forgot-password">Forgot password</Link>
        </li>
        <li>
          <Link href="/reset-password">Reset password</Link>
        </li>
        {isLogged ? (
          <LoadingButton
            loading={isLoading}
            onClick={handleSignOut}
            className="dd-btn dd-btn--primary"
          >
            Sign out
          </LoadingButton>
        ) : null}
        {isLogged ? (
          <li>
            <Link href="/kiotviet-connect">Kết nối KiotViet</Link>
          </li>
        ) : null}
        {store ? (
          <LoadingButton onClick={handleOpenSite} className="dd-btn dd-btn--primary">
            Open store
          </LoadingButton>
        ) : null}
      </ul>
    </LayoutMain>
  )

  // return (
  //   <Layout>
  //     <ul>
  //       <li>
  //         <Link href="/">Home</Link>
  //       </li>
  //       <li>
  //         <Link href="/login">Login</Link>
  //       </li>
  //       <li>
  //         <Link href="/sign-up">Register</Link>
  //       </li>
  //       <li>
  //         <Link href="/forgot-password">Forgot password</Link>
  //       </li>
  //       <li>
  //         <Link href="/reset-password">Reset password</Link>
  //       </li>
  //       {isLogged ? (
  //         <LoadingButton
  //           loading={isLoading}
  //           onClick={handleSignOut}
  //           className="dd-btn dd-btn--primary"
  //         >
  //           Sign out
  //         </LoadingButton>
  //       ) : null}
  //       {isLogged ? (
  //         <li>
  //           <Link href="/kiotviet-connect">Kết nối KiotViet</Link>
  //         </li>
  //       ) : null}
  //     </ul>
  //   </Layout>
  // )
}
