import React, { useState } from 'react'
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Image from 'next/image'
import Link from 'next/link'
import Layout from '@/components/app/Layout'

export interface LayoutMainProps {
  children: React.ReactNode
}

export default function LayoutMain(props: LayoutMainProps) {
  return (
    <Layout>
      <Container>
        <Box mt={3}>
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            justifyContent={{ xs: 'center', sm: 'space-between' }}
          >
            <Link href="/">
              <Image
                src={`${process.env.PUBLIC_URL}/logo.svg`}
                alt="Logo"
                width={356}
                height={69}
                priority
                className="dd-logo"
              />
            </Link>
          </Stack>
        </Box>
        {props.children}
      </Container>
    </Layout>
  )
}
