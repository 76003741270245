import { signOut, useSession } from 'next-auth/react'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'

export default function useAuth(shouldRedirect: boolean, isSite = false) {
  const { data: session }: any = useSession()
  const router = useRouter()
  const [isAuthenticated, setIsAuthenticated] = useState(false)

  useEffect(() => {
    if (session?.error === 'RefreshAccessTokenError') {
      signOut({ callbackUrl: '/login', redirect: shouldRedirect })
    }

    if (session === null) {
      if (router.route !== '/login' && isSite) {
        router.replace(`${process.env.PUBLIC_URL}/login`)
      }
      if (router.route !== '/login') {
        router.replace('/login')
      }
      setIsAuthenticated(false)
    } else if (session !== undefined) {
      if (router.route === '/login') {
        router.replace('/')
      }
      setIsAuthenticated(true)
    }
  }, [session, router, shouldRedirect])

  return isAuthenticated
}
