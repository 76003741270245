import moment from 'moment'

const FORBIDDEN_TERMINAL_CHARACTERS = [
  `!`,
  `#`,
  `$`,
  `%`,
  `&`,
  `'`,
  `*`,
  `+`,
  `-`,
  `/`,
  `=`,
  `?`,
  `^`,
  `_`,
  '`', // single quotes around tick
  `{`,
  `|`,
  `}`,
  `~`,
]

export const emailIsValid = (email: any) => {
  let syntaxGood = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
  if (!syntaxGood) return false // skip loop if we've already failed

  for (let badChar of FORBIDDEN_TERMINAL_CHARACTERS) {
    if (email.includes(badChar)) {
      return false // terminate early
    }
  }

  return true
}

export const kFormatter = (num: number) => {
  const formatter: string = (Math.abs(num) / 1000).toFixed(1)
  return Math.abs(num) > 999
    ? Math.sign(num) * Number(formatter) + 'k'
    : Math.sign(num) * Math.abs(num)
}

export const formatDate = (date: string, format = 'DD/MM/YYYY') => {
  return moment(date).format(format)
}

export const getKey = (length = 10) => {
  let a = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890'.split('')
  let b = []
  for (let i = 0; i < length; i++) {
    let j: any = (Math.random() * (a.length - 1)).toFixed(0)
    b[i] = a[j]
  }
  return b.join('')
}

export const getStoreLink = (url: string) => {
  if (window.location.pathname.indexOf('store') > -1) {
    let paths = window.location.pathname.split('/')
    if (paths.length > 2) {
      url = `/${paths[1]}/${paths[2]}${url}`
    }
  }
  return url
}

export const openSiteManager = (subdomain: string) => {
  let appEnv = process.env.APP_ENV
  let url = `${process.env.SECURE}${subdomain}.${process.env.DOMAIN}/manager/tables`
  switch (appEnv) {
    case 'local':
      url = `${process.env.SECURE}${subdomain}.${process.env.DOMAIN}${getStoreLink(
        '/manager/tables'
      )}`
      break
    case 'staging':
      url = `${process.env.PUBLIC_URL}/store/${subdomain}/manager/tables`
      break
    case 'production':
      url = `${process.env.PUBLIC_URL}/store/${subdomain}/manager/tables`
      break
    default:
      break
  }
  window.location.href = url
}

export const removeVietnameseTones = (str: string) => {
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a')
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e')
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i')
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o')
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u')
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y')
  str = str.replace(/đ/g, 'd')
  str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, 'A')
  str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, 'E')
  str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, 'I')
  str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, 'O')
  str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, 'U')
  str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, 'Y')
  str = str.replace(/Đ/g, 'D')
  // Some system encode vietnamese combining accent as individual utf-8 characters
  // Một vài bộ encode coi các dấu mũ, dấu chữ như một kí tự riêng biệt nên thêm hai dòng này
  str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, '') // ̀ ́ ̃ ̉ ̣  huyền, sắc, ngã, hỏi, nặng
  str = str.replace(/\u02C6|\u0306|\u031B/g, '') // ˆ ̆ ̛  Â, Ê, Ă, Ơ, Ư
  // Remove extra spaces
  // Bỏ các khoảng trắng liền nhau
  str = str.replace(/ + /g, ' ')
  str = str.trim()
  // Remove punctuations
  // Bỏ dấu câu, kí tự đặc biệt
  str = str.replace(
    /!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|_|`|-|{|}|\||\\/g,
    ' '
  )
  return str
}
